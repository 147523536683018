<template>
  <div class="home-container">
   <!-- <div class="entrance"  @click="handleGoSupplier"></div> -->
    <!-- banner -->
    <div class="banner">
      <swiper :options="banner.swiperOption" ref="bannerSwiper">
        <swiper-slide v-for="(item, index) in banner.list" :key="index">
          <div class="cover-image" :style="{ backgroundImage: `url('${item.path_url}')` }">
          </div>
          <img class="banner-image" :src="item.path_url"
             @click="handleBannerClick(item)"
             @mouseover="on_bot_enter('bannerSwiper')"
             @mouseleave="on_bot_leave('bannerSwiper')"
          />
        </swiper-slide>
        <div class="swiper-pagination index-swiper" slot="pagination"></div>
      </swiper>
      <Search
        class="search"
        :placeholder="searchPlaceholder"
        @onSelectChange="handleSearchSelectChange"
        @search="handleSearch"
      />
    </div>
    <!-- 音乐标签 -->
    <div class="music-tag">
      <div
        v-for="(item, index) in musicRecommendTags"
        :key="index"
        class="content">
        <div class="header">
          <div class="header-name">{{ item.label_name }}</div>
          <img :src="item.icon" />
        </div>
        <div class="tag">
          <Tag
            v-for="(tag, tagIndex) in item.son.slice(0, 10)"
            :key="tagIndex"
            class="tag-item"
            @onTag="handleTagClick(tag)">{{ tag.label_name }}</Tag>
        </div>
      </div>
    </div>
    <!-- 精选歌单 -->
    <div class="home-song-list">
      <Group-title
        :main-title="['精选', '歌单']"
        sub-title="SELECTED PLAYLISTS"
        align="center"
      />
      <div class="home-song-list-content">
        <div v-for="(item, index) in songList.data" :key="index">
          <div class="song-list">
            <SongList
              v-for="(subItem, subIndex) in item"
              :key="subIndex"
              :data="subItem"
              :row-index="subIndex"
              :hover-index="subIndex"
              :checked="
                songList.rowIndex === index &&
                songList.currentId === subItem.playlist_id
              "
              :isShowPlay="songList.hoverIndex == index && songList.currentId === subItem.playlist_id"
              @onMouseover="handleSongMouseover(subItem, index)"
              @onMouseleave="handleSongMouseLeave"
              @onPlay="handlePlayAll(subItem, 2)"
              @onClick="handleClickGoDetail(subItem)"
            />
          </div>
          <MusicList
            v-show="songList.hoverIndex === index"
            :showOrder=false
            :data.sync="songList.music"
            @onLeave="handleSongMouseLeave"
          />
        </div>
      </div>
      <div class="home-btn-box" @click="$router.push('/all-play-list')">查看更多</div>
    </div>
    <!-- 推荐视频 -->
    <div v-if="video.data.length > 0" class="home-video">
      <div class="home-video-bg">
        <div class="home-video-top">
          <Group-title
            :main-title="['推荐', '视频']"
            sub-title="RECOMMEND VIDEO"
            align="left"
            light
            :reverse="true"
          />
          <div class="arrow-btn">
            <div
              :class="{ disable: video.currentIndex + 2 === 5 }"
              @click="videoSlideNext">
              <i class="el-icon-back"></i>
            </div>
            <div
              :class="{ disable: video.currentIndex === 0 }"
              @click="videoSlidePrev">
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
      <swiper
        class="home-video-swiper swiper-no-swiping"
        :options="video.swiperOption"
        ref="videoSwiper">
        <swiper-slide v-for="(item, index) in video.data" :key="index">
          <VideoCard
            :data="item"
            :key="index"
            :collected.sync="item.is_collected"
            @onClick="gotoVideoDetail(item.object_id)" />
        </swiper-slide>
      </swiper>
      <div class="home-video-more">
        <span @click="gotoVideoList">查看更多</span>
        <img @click="gotoVideoList" :src="require('@/assets/image/home-video-more.png')" />
      </div>
    </div>
    <!-- 最新音乐 -->
    <div class="home-music">
      <Group-title
        :main-title="['热门', '音乐']"
        sub-title="THE POPULAR MUSIC"
        align="center"
      />
      <ul class="home-music-list">
        <li
          class="home-music-list-item"
          v-for="(item, index) in recommendMusic"
          :key="index">
          <div
            class="cover"
            @click="handlePlayerMusic({ ...item, type: 2, labels: item.label })">
            <el-image
              :fit="'cover'"
              class="cover-fixed"
              :src="require('@/assets/svg/record.svg')"
            />
            <img class="cover-main" :src="item.logo" />
            <div class="cover-btn">
              <img />
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ item.name }}</div>
            <div class="content-intro">BPM：{{ item.bpm }}</div>
            <div class="content-operation">
              <p class="content-operation-tag">
                <Tag
                  v-for="(tag, tagIndex) in item.label.length
                    ? item.label.slice(0, 2)
                    : []"
                  :key="tagIndex">{{ tag.name }}
                </Tag>
              </p>
              <p class="content-operation-btn">
                <img
                  :src="
                    require('@/assets/svg/home-recommend-music-download.svg')
                  "
                  @click="handleDownload(item, 'single')"
                />
                <span
                  class="btn-car"
                  @click="
                    handleAddGoodsToCart({ object_id: item.object_id, type: 2, name: item.name })
                  ">加入购物车</span>
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="home-btn-box home-btn-box-music"
        @click="
          () => {
            this.$router.push('/music')
          }
        ">
        发现更多音乐
      </div>
    </div>
    <!-- 热搜音效 -->
    <div class="home-sound-effect">
      <Group-title
        :main-title="['热搜', '音效']"
        sub-title="HOT SEARCH SOUND"
        align="center"
        light
      />
      <div class="home-sound-effect-content">
        <SoundEffectCard
          v-for="(item, index) in recommendSoundEffect"
          :key="index"
          :data="item"
        />
      </div>
      <div class="home-btn-box home-btn-box-light" @click="$router.push('/soundEffect')">查看更多</div>
    </div>
    <!-- 商用授权保障 -->
    <div class="home-authorization">
      <Group-title
        :main-title="['PLAYLIST商用', '授权保障']"
        sub-title="COMMERCIAL AUTHORIZATION GUARANTEE"
        align="center"
      />
      <div class="home-authorization-subtitle">正版授权，100%正品保障！</div>
      <div class="home-authorization-content">
        <div
          class="item"
          v-for="(item, index) in authorizationList"
          :key="index">
          <div class="item-badge">0{{ index + 1 }}</div>
          <div class="item-icon">
            <img :src="item.icon" />
          </div>
          <p class="item-title">{{ item.title }}</p>
          <p class="item-info">{{ item.info[0] }}<br />{{ item.info[1] }}</p>
        </div>
      </div>
    </div>
    <!-- 优秀博主 -->
    <div class="home-blogger">
      <Group-title
        :main-title="['合作', '博主']"
        sub-title="COOPERATION BRAND"
        align="center"
        light
      />
      <div class="home-authorization-subtitle" style="color:rgba(255,255,255,.7)">这些优秀的博主都在使用PlayList</div>
      <div class="home-blogger-cont">
        <swiper :options="blogger.swiperOption" ref="bloggerSwiper">
          <swiper-slide v-for="(item, ind) in bloggerList" :key="ind">
            <div class="home-blogger-detail">
              <div class="home-blogger-avatar">
                <img :src="item.avatar" alt="">
              </div>
              <div class="home-blogger-info">
                <span>{{ item.name }}</span>
                <span>{{ item.platform }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 合作品牌 -->
    <div class="home-brand">
      <Group-title
        :main-title="['合作', '品牌']"
        sub-title="COOPERATION BRAND"
        align="center"
      />
      <div class="home-brand-content">
        <img :src="require('@/assets/image/brand.png')" />
      </div>
    </div>

    <!--双十一活动弹框 截止到2022.11.15-->
    <dobuleElevenDialog
      v-if="dobuleEleven && showActive20230618"
      @showDialog="dobuleEleven=false"
    />
    <!--双十一活动弹窗贴片 截止到2022.11.15-->
    <div v-if="showActive20230618" class="dobuleElevenActive"  @click="handleGoSupplier('dobuleElevenActive')"></div>
    <!-- 创作者活动弹框 -->
    <!-- <el-dialog
      class="dialog-for-creator"
      :visible="dialogVisiblecreator"
      :destroy-on-close="false"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleClosecreator"
      center>
      <div class="creator_dialog">
        <div class="creator_content">
          <img src="@/assets/image/creator_dialog.png" alt="" @click="goCreator">
        </div>
      </div>
    </el-dialog> -->

    <!-- 注册/分享推广即送会员 -->
    <!-- <el-dialog
      class="dialog-for-member"
      :visible="dialogVisiblemember"
      :destroy-on-close="false"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleBeforeClose"
      center
    >
      <div class="member_dialog">
        <div class="member_content">
          <img :src="memberUrl" alt="" @click="goMember" :style="!userInfo ? 'width:390px' : 'width: 360px'">
        </div>
      </div>
    </el-dialog> -->

    <!-- 注册/分享推广即送会员小图标 -->
    <!-- <img :src="memberUrl" class="register_member" @click="goMember" v-show="showMember" :class="{'active': showMember}"> -->
  </div>
</template>
<script>
import {
  fetchRecommendBannerList,
  recommendMusic,
  recommendPlaylist,
  recommendSoundEffect,
  recommendTags,
  recommendVideo
} from "@/api/home";
import dobuleElevenDialog from "@/views/activity/dobuleEleven/components/PcDialog";
import { playlistMusic } from "@/api/music";
import { bloggerList, HeaderMenuList } from "@/config";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Search from "@/components/Search";
import Tag from "@/components/Tag";
import GroupTitle from "./components/GroupTitle";
import SongList from "@/components/SongList";
import MusicList from "@/components/MusicList";
import VideoCard from "@/components/VideoCard";
import SoundEffectCard from "@/components/SoundEffectCard";
import Buttons from "@/layout/mixins/Buttons";
import { send_eventApi } from "@/utils/common.js";
import { mapGetters, mapState } from "vuex";
// import { setStorage, getStorage } from '@/utils/sessionStroge.js'
import { setStorageUserId } from '@/utils/sessionStroge.js';
import { userIncline } from '@/api/member.js';
import { getUserId, getToken } from "@/utils/author";
const showActive20230618 = new Date().getTime() <= new Date('2023/06/23 23:59:59').getTime()
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    Search,
    Tag,
    GroupTitle,
    SongList,
    MusicList,
    VideoCard,
    SoundEffectCard,
    dobuleElevenDialog
  },
  data() {
    return {
      showActive20230618,
      dobuleEleven: false,
      HeaderMenuList,
      bloggerList,
      searchPlaceholder: "",
      dialogVisiblemember: false, // 推广分享弹窗
      dialogVisiblecreator: false, // 创作者活动弹框
      memberUrl: require("@/assets/image/shareImg1.png"),
      // btnplay: true,
      banner: {
        list: [
          { path: "/mid-autumn-festival", url: require("@/assets/image/banner/2022-mid-autumn-festival.jpg") },
          { path: "/teacher-day", url: require("@/assets/image/banner/2022-teachers-day.jpg") },
          { path: "", url: require("@/assets/image/active-banner-2022-08-10.jpg") },
          { path: "/camping", url: require("@/assets/image/campingPic/camping-banner.jpg") },
          { path: "/activity/creatorActive", url: require("@/assets/image/creator-banner.jpg") },
          { path: "https://www.wenjuan.com/s/r2uMjyu/", url: require("@/assets/image/questionnaire_banner.jpg") },
          {
            path: "https://space.bilibili.com/396766661",
            url: "https://qkodo.playlistmusic.com.cn/default%2F%E7%AC%AC%E4%B8%89%E6%9C%9F%E4%BB%A3%E8%A8%80%E4%BA%BAbanner.jpg",
            login: true
          },
          { path: "/package/page", url: require("@/assets/image/member_banner.jpg") }
        ],
        swiperOption: {
          // banner轮播配置项
          loop: false,
          simulateTouch: false, // 设置鼠标拖动无效
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          // 设置点击箭头
          navigation: {
            nextEl: ".banner-swiper-button-next",
            prevEl: ".banner-swiper-button-prev"
          }
          // on: {
          //   click: function () {
          //       // this.realIndex 获取当前swiper的真实索引
          //       console.log(this.realIndex);
          //       self.bannerEvent(this.realIndex)
          //   }
          // }
        }
      },
      musicRecommendTags: [], // 音乐推荐标签
      songList: {
        // 精选歌单
        data: [], // 歌单列表
        music: [], // 当前歌单对应的音乐列表
        currentId: 0, // 当前歌单id
        rowIndex: -1, // 行下标
        hoverIndex: -1
      },
      video: {
        // 推荐视频
        data: [],
        currentIndex: 0, // 视频轮播图当前下标
        swiperOption: {
          // 视频轮播配置项
          loop: true,
          autoplay: {
            delay: 4000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          slidesPerView: 3, // 每页展示几条数据
          slidesPerGroup: 1, // 几条数据为一组
          spaceBetween: 24 // 各slide的间距
          // centeredSlides: true,
          // loopedSlides: 5, // 每次循环的个数
          // // 设置点击箭头xx
          // navigation: {
          //   nextEl: '.el-icon-back',
          //   prevEl: '.el-icon-next'
          // },
          // slidesOffsetBefore: 199, // 与左边框距离
          // slidesOffsetAfter: 100 // 与右边框距离
        }
      },
      // 优秀博主轮播
      blogger: {
        swiperOption: {
          // 视频轮播配置项
          loop: false,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          slidesPerView: 5, // 每页展示几条数据
          slidesPerGroup: 1, // 几条数据为一组
          spaceBetween: 30 // 各slide的间距
          // centeredSlides: true,
          // loopedSlides: 5, // 每次循环的个数
          // // 设置点击箭头xx
          // navigation: {
          //   nextEl: '.el-icon-back',
          //   prevEl: '.el-icon-next'
          // },
          // slidesOffsetBefore: 199, // 与左边框距离
          // slidesOffsetAfter: 100 // 与右边框距离
        }
      },
      recommendMusic: [], // 最新音乐
      recommendSoundEffect: [], // 热搜音效
      authorizationList: [
        // 授权保障
        {
          icon: require("@/assets/svg/authorization-login.svg"),
          title: "注册登录",
          info: ["多种登录方式", "灵活便捷"]
        },
        {
          icon: require("@/assets/svg/authorization-resources.svg"),
          title: "选择资源",
          info: ["音乐、音效、视频", "最新热门、专属推荐等"]
        },
        {
          icon: require("@/assets/svg/authorization-vip.svg"),
          title: "购买会员",
          info: ["性价比超高", "实惠套餐"]
        },
        {
          icon: require("@/assets/svg/authorization-book.svg"),
          title: "下载授权书",
          info: ["覆盖全站", "商用正版版权"]
        },
        {
          icon: require("@/assets/svg/authorization-invoice.svg"),
          title: "售后/发票",
          info: ["24小时售后服务", "随时随地反馈"]
        }
      ],
      user_id: "" // 分享用户id
    };
  },
  mixins: [Buttons],
  computed: {
    ...mapGetters({
      userInfo: "userInfo"
    }),
    ...mapState({
      showMember: (state) => state.user.showMember,
      playSongList: (state) => state.music.orderPlayList
    }),
    swiper() {
      return this.$refs.bannerSwiper.swiper;
    }
  },
  async created() {
    await this.getRecommendBannerList();
    await this.getRecommendTags();
    await this.getRecommendPlaylist();
    await this.getRecommendVideo();
    await this.getRecommendMusic();
    await this.getRecommendSoundEffect();

    // 判断是否为分享打开的链接 获取分享人user_id
    this.user_id = this.GetQueryString("user_id");
    if (this.user_id && this.user_id !== null) {
      setStorageUserId(this.user_id);
      window.localStorage.setItem("share_token", this.user_id);
      // 初始化绑定助力用户 防止被分享用户产生购买行为还无效的情况
      const param = {
        share_token: this.user_id
      };
      await userIncline(param);
    }

    // 双十一活动弹框
    this.dobuleEleven = window.sessionStorage.getItem('showDialog') == 'false' ? false : true;
  },
  mounted() {
    // 判断用户是否登录 如果未登录且是通过推广分享进来的默认显示登录弹框 否则判断是是否为首次进入页面 true的话 不显示送会员
    if (!this.userInfo && this.user_id) {
      this.$store.dispatch("user/openLogin");
      // setStorage(false);
    } else {
      // if(!this.$route.query.isOpenLogin){
      //   if (!window.sessionStorage.getItem("isShowcreator")) {
      //     this.dialogVisiblecreator = true;
      //     window.sessionStorage.setItem("isShowcreator", true);
      //   } else {
      //     this.dialogVisiblecreator = false;
      //   }
      // }
    }
  },
  methods: {
    /**
     * 鼠标移入轮博暂停
     */
    on_bot_enter(name) {
      this.$refs[name]?.swiper.autoplay.stop();
    },
    slideChangeTransitionEnd() {
      this.bannerActiveIndex = this.swiper.realIndex
    },
    /**
     * 鼠标移出轮播开始
     */
    on_bot_leave(name) {
      this.$refs[name]?.swiper.autoplay.start();
    },
    /**
     * 获取地址栏参数
     */
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null)
        return decodeURI(r[2]);
      return null;
    },
    /**
     * 点击遮罩层关闭
     */
    // handleBeforeClose(){
    //   this.dialogVisiblemember = false;
    //   this.$store.dispatch('user/setMemberstatus', true)
    // },
    // 领取会员
    // goMember(){
    //   this.$router.push({path:'/activity/shareActive'})
    //   // if(!this.userInfo){
    //   //   this.$router.push({path:'/activity/active'})
    //   // }else{
    //   //   this.$router.push({path:'/activity/shareActive'})
    //   // }
    // },
    /**
     * 点击关闭创作者活动弹框
     */
    handleClosecreator() {
      this.dialogVisiblecreator = false;
    },
    /**
     * 进入创作者活动页
     */
    goCreator() {
      this.$router.push({ path: "/package?type=2" });
    },
    /**
     * 处理banner点击事件
     * 2022-08-16 14:28:56
     * @author SnowRock
     * @param item
     * @param item.path 地址 vue项目地址 或者 实际的http地址
     * @param item.login 是否需要登录 默认不需要登录
     */
    handleBannerClick(item) {
      const obj = {
        m_name: `banner_${item.title}`,
        mid: item.banner_id || '',
        user_id: this.userInfo ? this.userInfo.user_id : ""
      };
      send_eventApi("e_banner", obj);

      // 双十一活动
      if(item.link && item.link.search("doubleEleven") > -1){
        if (!this.userInfo && !getToken()) {
          this.$store.dispatch("user/openLogin", true);
          return;
        }
      }
      // 供稿入住
      if(item.link && item.link.search("creatorCenter") > -1){
        if (!this.userInfo && !getToken()) {
          this.$store.dispatch("user/openLogin", true);
          return;
        }
      }

      const { link = "", login = false } = item;
      if (link) {
        const isOur = link.indexOf("http") === -1;
        // 如果是内部的
        if (isOur) {
          if (login) {
            if (!this.userInfo) {
              this.$store.dispatch("user/openLogin", true);
              return;
            }
          }
          this.$router.push({
            path: link
          });
        } else {
          if(link.search("creatorCenter") > -1){
            window.open(`${link}?userId=${this.userInfo.user_id}`);
          }else{
            window.open(link);
          }
          // window.open(link);
        }
      }
    },
    /**
     * 点击banner埋点事件
     */
    bannerEvent(index) {
      const obj = {
        m_name: "banner",
        mid: "",
        user_id: this.userInfo ? this.userInfo.user_id : ""
      };
      send_eventApi("e_banner", obj);
      if (index === 0) {
        // 1
      } else if (index === 1) {
        this.$router.push({ path: "/camping" });
      } else if (index === 2) {
        this.$router.push({ path: "/activity/creatorActive" });
      } else if (index === 3) {
        window.open("https://www.wenjuan.com/s/r2uMjyu/");
      } else if (index === 4) {
        window.open("https://space.bilibili.com/396766661");
        // window.open('https://www.douyin.com/user/MS4wLjABAAAAwaG8Dg7k1yWKS2jfg_JhDgVAXEdJ5DYhNlxFmqTLBDs')
        // if(!this.userInfo){
        //   this.$store.dispatch('user/openLogin', true);
        // }else{
        //   this.$router.push({ path: '/package/page', query: { type: 2} })
        // }
      } else if (index === 5) {
        if (!this.userInfo) {
          this.$store.dispatch("user/openLogin", true);
        } else {
          this.$router.push({ path: "/package/page" });
        }
      } else {
        if (!this.userInfo) {
          this.$store.dispatch("user/openLogin", true);
        } else {
          this.$router.push({ path: "/package/page" });
        }
      }
    },
    /**
     * 获取音乐推荐标签
     */
    async getRecommendTags() {
      try {
        const { data } = await recommendTags();
        this.musicRecommendTags = data;
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取首页banner推荐信息
     * 2022-08-17 10:51:05
     * @author SnowRock
     */
    async getRecommendBannerList() {
      try {
        const { data } = await fetchRecommendBannerList();
        this.banner.list = data.list
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取精选歌单列表
     */
    async getRecommendPlaylist() {
      try {
        const { data } = await recommendPlaylist();
        let temp = [];
        if (data.list.length) {
          data.list.forEach((item, index) => {
            temp[parseInt((index + 1) / 5)] = [];
          });
          data.list.forEach((item, index) => {
            temp[parseInt(index / 5)].push(item);
          });
        }
        this.songList.data = JSON.parse(JSON.stringify(temp));
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取推荐视频
     */
    async getRecommendVideo() {
      try {
        const { data } = await recommendVideo();
        this.video.data = data.map(i => {
          return {
            ...i,
            is_collected: i.is_collect
          };
        });
        // console.log(this.video.data, '推荐视频')
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取最新音乐
     */
    async getRecommendMusic() {
      try {
        const { data } = await recommendMusic();
        this.recommendMusic = data.list;
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 获取热搜音效
     */
    async getRecommendSoundEffect() {
      try {
        const { data } = await recommendSoundEffect();
        this.recommendSoundEffect = data.list;
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * 点击搜索下拉框项
     * @param {Object} e 详情
     */
    handleSearchSelectChange(e) {
      for (let i = 0; i < HeaderMenuList.length; i++) {
        if (HeaderMenuList[i].path === e.path) {
          this.searchPlaceholder = HeaderMenuList[i].searchPlaceholder;
          return;
        }
      }
    },

    /**
     * 点击音乐推荐标签
     * @param {Object} item 标签详情
     */
    handleTagClick({ label_id, label_name }) {
      console.log(this.userInfo, label_name);
      const obj = {
        label_name: label_name,
        user_name: this.userInfo ? this.userInfo.name : "",
        user_id: this.userInfo ? this.userInfo.user_id : ""
      };
      send_eventApi("e_slt", obj);
      this.$router.push({
        path: "/search-result",
        query: {
          label_id,
          label_name
        }
      }); // 跳转到音乐搜索结果页
    },
    async handleSongMouseover(ele, rowIndex) {
      // console.log(rowIndex, '下标')
      this.songList.currentId = ele.object_id;
      this.songList.hoverIndex = rowIndex;

      // if (
      //   this.songList.rowIndex === rowIndex &&
      //   this.songList.currentId === ele.object_id
      // ) {
      //   // 音乐收起
      //   this.songList.rowIndex = -1;
      // } else {
        // 音乐展开
        this.songList.currentId = ele.object_id;
        this.songList.rowIndex = rowIndex;
        try {
          const { data } = await playlistMusic({
            playlist_id: this.songList.currentId
          });
          this.songList.music = data;
        } catch (e) {
          console.log(e);
        }
      // }
    },

    handleSongMouseLeave() {
      this.songList.hoverIndex = -1
    },
    /**
     * 点击歌单 跳转歌单详情
     * @param {Object} e 子组件传参  "8800eb241e690000001"
     */
    async handleClickGoDetail(ele) {
      this.$router.push(`/music/detail/${ele.object_id}`);
      const obj = {
        m_name: ele.name,
        mid: ele.object_id,
        user_name: this.userInfo ? this.userInfo.name : "",
        user_id: this.userInfo ? this.userInfo.user_id : ""
      };
      send_eventApi("e_playlist", obj);
    },
    /**
     * 播放全部
     */
    async handlePlayAll(ele, type) {
      // 音乐展开
      this.songList.currentId = ele.object_id;
      this.songList.rowIndex = -1;
      try {
        const { data } = await playlistMusic({
          playlist_id: this.songList.currentId
        });
        // console.log(data)
        this.$store.dispatch("music/updateOrderList", data);
        await this.handlePlayerAll(data[0], type); // 限制音乐/音效试听播放次数
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * 切换下一条视频
     */
    videoSlideNext() {
      // if (this.video.currentIndex + 2 === 5) return
      this.$refs.videoSwiper.swiper.slideNext();
      this.video.currentIndex = this.video.currentIndex + 1;
    },

    /**
     * 切换上一条视频
     */
    videoSlidePrev() {
      // if (this.video.currentIndex === 0) return
      this.$refs.videoSwiper.swiper.slidePrev();
      this.video.currentIndex = this.video.currentIndex - 1;
      // this.video.swiperOption.loop = true;
    },

    /**
     * 跳转到视频详情页
     * @param {String} videoId 视频id
     */
    async gotoVideoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId);

      if (!result) return;
      this.$router.push(`/video/detail?videoId=${videoId}`);
    },

    /**
     * 跳转到视频列表页
     */
    gotoVideoList() {
      this.$router.push('/video')
    },
    // 入口跳转
    handleGoSupplier(name) {
      const userId = getUserId()
      if(!getToken() || !userId) {
        this.$store.dispatch('user/openLogin');
        return
      }
      if(name == 'dobuleElevenActive'){
        // 双十一活动
        this.$router.push({
          path: "/activityFor618"
        });
        return;
      }


      // 线上环境地址：https://s.playlistmusic.com.cn
      // 测试环境地址： https://t_tests.playlistmusic.com.cn
      let url = process.env.NODE_ENV === 'production' ? 'https://s.playlistmusic.com.cn' : 'https://t_tests.playlistmusic.com.cn'
       window.open(`${url}?userId=${userId}`)

    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes entranceAni {
    0% {
        bottom: 40px;
    }
    100% {
        bottom: 30px
    }
}
.home-container {
  min-width: 1440px;
  .entrance {
    animation: entranceAni 1s linear infinite alternate-reverse;
    width:180px;
    height: 168px;
    position: fixed;
    left: 40px;
    bottom: 30px;
    z-index: 999;
    background: url('../../assets/image/entrance.png') no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .dobuleElevenActive{
    animation: entranceAni 1s linear infinite alternate-reverse;
    width:180px;
    height: 228px;
    position: fixed;
    left: 40px;
    bottom: 30px;
    z-index: 999;
    background: url('../../assets/image/active-618.png') no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  // banner
  .banner {
    width: 100%;
    position: relative;
    max-height: 634px;
    text-align: center;
    overflow: hidden;

    .swiper-container {
      .swiper-pagination-bullets {
        bottom: 70px !important;

        /deep/ .swiper-pagination-bullet {
          display: inline-block !important;
          width: 24px !important;
          height: 8px !important;
          background: rgba(255, 255, 255, .6) !important;
          border-radius: 6rem !important;

          &.swiper-pagination-bullet-active {
            display: inline-block !important;
            width: 30px !important;
            height: 8px !important;
            background: rgba(255, 255, 255, .7) !important;
            border-radius: 6px !important;
            -webkit-transition: all .3s ease-in-out;
          }
        }
      }
    }

    .erweima {
      z-index: 1000;
      position: fixed;
      width: 160px;
      height: 160px;
      top: 166px;
      right: 186px;
    }

    .banner-image {
      height: 100%;
      margin: 0 auto;
      max-height: 634px;
      position: relative;
      z-index: 20;
      cursor: pointer;
    }

    .cover-image {
      width: calc(100% + 20px);
      height: 654px;
      position: absolute;
      z-index: 1;
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(5px);
      left: -5px;
      top: -5px;
    }
  }

  // 搜索
  .search {
    @include custom-position(
      $position: absolute,
      $left: 50%,
      $bottom: 135px,
      $zindex: 2
    );
    transform: translateX(-50%);
  }

  // 音乐标签
  .music-tag {
    @include center-middle-flex;
    margin-top: -48px;
    @include custom-position($position: relative, $left: 50%, $zindex: 1);
    transform: translateX(-50%);
    width: 100%;
    max-width: 1320px;

    .content {
      @include size(254px, auto);
      margin-right: 24px;
      padding: 12px 0px 6px 16px;
      background: $base-color;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;

      .header {
        @include center-middle-flex($justify: space-between);
        line-height: 22px;
        margin-right: 16px;

        &-name::before {
          content: '';
          display: inline-block;
          height: 16px;
          border-left: 3px solid rgba($theme-color, 0.48);
          border-radius: 2px;
          margin-right: 8px;
        }

        &-name {
          @include font-bold($size: $font-size-sl);
          @include center-middle-flex;
        }

        img {
          @include square(20px);
        }
      }

      .tag {
        margin-top: 17px;

        &-item {
          margin-bottom: 12px;
        }

        &-item:active {
          background: $theme-color;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }

    .content:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  // 精选歌单
  .home-song-list {
    @include center-middle-flex;
    @include flex-direction(column);
    margin-top: 96px;

    &-content {
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
      margin-top: 21px;

      .song-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 26px 27px;
        grid-auto-flow: row;
        margin-top: 27px;
      }
    }
  }

  // 查看更多按钮
  .home-btn-box {
    margin: 64px 0;
    cursor: pointer;
    @include font-normal($size: $font-size-xl);
    padding: 10px 38px;
    border-radius: 2px;
    border: 2px solid $font-theme-color;

    &-light {
      border-color: $base-color;
      @include font-color($font-initialize-color);
    }

    &-music {
      padding: 10px 16px;
    }
  }

  // 推荐视频
  .home-video {
    background: rgba(245, 246, 250, 0.36);

    &-bg {
      @include size(90%, 450px);
      background: rgba(31, 32, 37, 0.9);
      border-top-right-radius: 180px;
      position: relative;

      .home-video-top {
        margin: 0 150px 81px 199px;
        padding-top: 80px;
        @include center-middle-flex($justify: space-between);

        .arrow-btn {
          @include center-middle-flex;

          div {
            @include square(48px);
            @include center-middle-flex;
            background: #c3c3cd;
            border-radius: 50%;
            cursor: pointer;

            i {
              @include font-heavy($size: 20px);
            }
          }

          div:nth-of-type(1) {
            margin-right: 16px;
          }

          .disable {
            background: rgba(245, 246, 250, 0.36);
          }
        }
      }
    }

    &-swiper {
      padding-bottom: 118px;
      margin-top: calc(85px - 288px);
      // margin-left: 180px;
      // padding-left: 100px;
      .swiper-slide {
        width: auto !important;

        .vf-video-card {
          @include size(426px, 288px);

          /deep/ .info {
            @include size(282px, 150px);
            padding: 20px 22px 24px 20px;

            &-title {
              line-height: 28px;
              font-size: 20px;
            }

            &-tag {
              margin-top: 4px;
              line-height: 22px;

              .vf-tag-text {
                font-size: 16px;
              }
            }

            &-operation {
              .car {
                @include size(90px, 32px);
              }
            }
          }
        }
      }
    }

    &-more {
      @include center-middle-flex($justify: flex-end);
      @include font-normal($size: $font-size-xl);
      line-height: 30px;
      padding-top: 20px;
      padding-bottom: 52px;

      span {
        cursor: pointer;
        padding-right: 16px;
      }

      img {
        @include size(40px, 14px);
        margin-right: 133px;
        cursor: pointer;
      }
    }
  }

  // 最新音乐
  .home-music {
    margin-top: 64px;
    @include center-middle-flex;
    @include flex-direction(column);

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 26px;
      grid-auto-flow: row;
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
      margin-top: 48px;

      &-item:hover {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
        border: none;
      }

      &-item {
        @include center-middle-flex($justify: flex-start);
        background: $base-color;
        @include size(383px, 122px);
        padding: 15px 16px;
        border: 2px solid rgba(77, 77, 79, 0.12);
        cursor: pointer;

        .cover {
          @include size(123px, 92px);
          margin-right: 12px;
          position: relative;
          border-radius: 2px;

          &-fixed {
            @include square(82px);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
          }

          &-main {
            @include square(92px);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            border-radius: 2px;
            object-fit: cover;
          }

          &-btn {
            @include square(92px);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 2px;
          }
        }

        .content {
          width: calc(100% - 123px);

          &-title {
            @include font-bold($size: 18px);
            @include text-overflow(226px);
            line-height: 25px;
            text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.24);
          }

          &-intro {
            @include font-normal($size: 14px);
            color: rgba(77, 77, 79, 0.48);
            line-height: 20px;
          }

          &-operation {
            @include center-middle-flex($justify: space-between);
            margin-top: 17px;

            .vf-tag-container {
              color: rgba(77, 77, 79, 0.8);
            }

            .vf-tag-block:nth-last-of-type(1) {
              margin-right: 0;
            }

            &-btn {
              @include center-middle-flex;

              img {
                @include square(24px);
                margin-right: 4px;
              }

              .btn-car {
                @include size(70px, 24px);
                @include font-normal(
                  $size: 12px,
                  $color: $font-initialize-color
                );
                line-height: 24px;
                text-align: center;
                background: linear-gradient(225deg, #E52A0D 0%, #ff7d68 100%);
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }

  // 热搜音效
  .home-sound-effect {
    background: #343539;
    padding: 48px 58px 16px 57px;
    @include center-middle-flex;
    @include flex-direction(column);

    &-content {
      width: 100%;
      max-width: 1320px;
      margin: 0 auto;
      margin-top: 64px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 26px 20px;
      grid-auto-flow: row;

      /deep/ .vf-sound-effect {
        .top-cover {
          margin-right: 16px;
        }
      }
    }
  }

  // 商用授权保障
  .home-authorization {
    margin-top: 36px;

    &-subtitle {
      @include font-bold($size: 20px, $color: rgba(0, 0, 0, 0.7));
      line-height: 28px;
      text-align: center;
      margin-top: 12px;
    }

    &-content {
      @include center-middle-flex;
      margin-top: 48px;

      .item:nth-last-of-type(1) {
        margin-right: 0;
      }

      .item {
        @include size(180px, 235px);
        @include center-middle-flex;
        @include flex-direction(column);
        margin-right: 27px;
        background: #ffffff;
        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.2),
        0px 0px 36px 0px #ffffff;
        border-radius: 8px 12px 12px 12px;
        position: relative;
        opacity: .5;
        cursor: pointer;

        &:hover {
          opacity: 1;
          -webkit-animation: hoverActive .5s ease-in-out forwards;
        }

        &-badge {
          @include square(36px);
          @include font-bold($size: 20px, $color: $font-initialize-color);
          padding-right: 4px;
          background: $theme-color;
          text-align: right;
          line-height: 36px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 8px 0 100% 0;
        }

        &-icon {
          @include square(51px);
          @include center-middle-flex;
          border-radius: 50%;
          background: rgba($theme-color, 0.1);

          img {
            @include square(30px);
          }
        }

        &-title {
          margin-top: 8px;
          margin-bottom: 18px;
          @include font-heavy($size: 18px);
          line-height: 25px;
        }

        &-info {
          text-align: center;
          @include font-normal($color: rgba($font-theme-color, 0.8));
        }
      }
    }
  }

  // 商用授权保障 鼠标滑入卡片效果
  @keyframes hoverActive {
    0% {
      -webkit-transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.05);
    }
  }
  // 优秀博主
  .home-blogger {
    width: 100%;
    height: 620px;
    padding: 80px 120px 81px 120px;
    margin-top: 82px;
    background: #343539;

    .home-blogger-cont {
      width: 100%;
      max-width: 1440px;
      margin: 89px auto 0px;

      .swiper-slide {
        width: 216px;

        .home-blogger-detail {
          width: 216px;
          height: 292px;
          display: flex;
          flex-direction: column;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.07);
          cursor: pointer;

          .home-blogger-avatar {
            width: 216px;
            height: 220px;

            img {
              width: 100%;
            }
          }

          .home-blogger-info {
            flex: 1;
            height: 72px;
            text-align: center;
            padding: 11px 0px;

            span {
              display: block;

              &:nth-of-type(1) {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
              }

              &:nth-of-type(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.75);
                padding-top: 4px;
              }
            }
          }
        }
      }
    }
  }

  // 合作品牌
  .home-brand {
    margin-top: 75px;
    margin-bottom: 64px;

    &-content {
      margin-top: 80px;

      img {
        width: 100%;
      }
    }
  }

  /deep/ .dialog-for-member, .dialog-for-creator {
    // position: fixed!important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-dialog {
      background: none !important;
      width: 499px;
      height: 522px;
      margin-top: 0 !important;
      box-shadow: none !important;

      .member_dialog, .creator_dialog {
        width: 100%;
        height: 100%;

        .member_content, .creator_content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 499px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.dialog-for-creator {
  /deep/ .el-dialog {
    width: 499px;
    background: none !important;
    box-shadow: none !important;

    .el-dialog__body {
      padding: 0px !important;
    }
  }
}

.register_member {
  z-index: 1000;
  position: fixed;
  left: 20px;
  bottom: 145px;
  width: 130px;
  cursor: pointer;

  &.active {
    -webkit-animation: skin 1s ease-in-out infinite;
  }

  @keyframes skin {
    0% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.3);
    }
    100% {
      -webkit-transform: scale(1);
      // opacity: 1;
    }
  }
}
</style>
