<template>
  <div :class="['group-title-container', `group-title-container-${align}`]">
    <div :class="['sub-title', { 'sub-title-second': reverse }]">
      {{ subTitle }}
    </div>
    <div
      :class="[
        'main-title',
        { 'main-title-light': light },
        { 'main-title-first': reverse }
      ]"
    >
      <span v-for="(item, index) in mainTitle" :key="index">{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupTitle',
  props: {
    mainTitle: {
      type: Array,
      defalut: () => []
    },
    subTitle: {
      type: String,
      defalut: ''
    },
    // 浅颜色文字
    light: {
      type: Boolean,
      defalut: false
    },
    // 对齐方式 'left'左对齐,'center'居中,'right'右对齐
    align: {
      type: String,
      defalut: 'center'
    },
    // 是否倒序
    reverse: {
      type: Boolean,
      defalut: false
    }
  }
}
</script>

<style lang="scss" scoped>
.group-title-container {
  @include flexbox;
  @include flex-direction(column);
  &-left {
    @include align-items(flex-start);
  }
  &-center {
    @include align-items(center);
  }
  &-right {
    @include align-items(flex-end);
  }
  .sub-title {
    @include font-normal($size: $font-size-lg);
    color: #c3c3cd;
    line-height: 28px;
  }
  .main-title {
    @include font-bold($size: $font-size-hg);
    color: $font-theme-color;
    line-height: 50px;
    span:nth-last-of-type(1) {
      color: $theme-color;
    }
  }
  .main-title-light {
    span:nth-of-type(1) {
      color: $font-initialize-color;
    }
  }
  .main-title-first {
    order: 0;
  }
  .sub-title-second {
    order: 1;
    margin-top: 8px;
  }
}
</style>
