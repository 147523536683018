/*
 * @Author: songyajuan
 * @Date: 2021-12-23 10:23:36
 * @Description: 首页相关请求
 * @Last Modified by: songyajuan
 * @Last Modified time: 2021-12-24 17:20:03
 */
import request from '@/utils/request'

/**
 * 音乐推荐标签
 */
export function recommendTags() {
  return request.post('/tjz/LabelList/list')
}

/**
 * 精选歌单
 * @param params
 * @param params.page 页码
 * @param params.limit 条数
 */
export function recommendPlaylist(params = { page: 1, limit: 15 }) {
  return request.post('/fontend/Music/careSongList', params, {})
}

/**
 * 推荐视频
 */
export function recommendVideo() {
  return request.post('/fontend/Music/recommandVideoList')
}

/**
 * 最新音乐
 * @param params
 * @param params.page 页码
 * @param params.limit 条数
 */
export function recommendMusic(params = { page: 1, limit: 9 }) {
  return request.post('/fontend/Music/recommandMusicList', params, {})
}

/**
 * 热搜音效
 * @param params
 * @param params.page 页码
 * @param params.limit 条数
 */
export function recommendSoundEffect(params = { page: 1, limit: 12 }) {
  return request.post('/fontend/Music/recommandSoundList', params, {})
}

/**
 * 获取推荐banner
 * 2022-08-17 10:49:54
 * @author SnowRock
 * @param params
 * @returns {*}
 */
export function fetchRecommendBannerList(params) {
  return request.post('/fontend/banner/list', params, {})
}
