<template>
  <div class="markbox" @click.prevent="cancel">
    <div class="dobuleEleven" :class="{'dobuleElevenH5': showType == 'h5'}">
      <h4 class="title">618年中大促</h4>
      <img src="../image/subtitle-618.png" class="subtitle" />
      <img src="../image/dialog/gift.png" class="gift" />
      <ul class="countdown">
        <li>{{countDownObj.day}}</li>
        <li>{{countDownObj.hour}}</li>
        <li>{{countDownObj.minutes}}</li>
        <li>{{countDownObj.seconds}}</li>
      </ul>
      <div class="surplus">
        <div class="surplustitle">
          <div class="today">今日</div>
          <div class="me">剩余名额</div>
        </div>
        <span class="num">{{5 || surplusNum}}个</span>
      </div>
      <div class="snapup" @click.stop="snap">立即抢购</div>
    </div>
  </div>
</template>
<script>
import {getToken} from "@/utils/author";
import { mapGetters } from 'vuex';
export default {
  data(){
    return{
      showType: null,
      endTime: null,
      countDownObj: {},
      timer: null
    }
  },
  created() {
    const ua = navigator.userAgent;
    if(ua.match(/(iPhone|iPad|Android|ios)/i)){
      this.showType = 'h5';
    }else{
      this.showType = 'pc';
    }
    // 倒计时
    this.endTime = new Date('2023/6/23 23:59:59').getTime();
    this.countdown();
  },
  computed:{
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    surplusNum(){
      return Math.floor(Math.random()*(5-1+1))+1;
    }
  },
  methods:{
    cancel(){
      window.sessionStorage.setItem('showDialog', 'false');
      this.$emit("showDialog", false);
      window.clearTimeout(this.timer);
    },
    snap(){
      window.sessionStorage.setItem('showDialog', 'false');
      this.$emit("showDialog", false);
      window.clearTimeout(this.timer);
      // if(this.showType == 'h5'){
      //   if(!this.userInfo && !getToken()){
      //     this.$router.push({ path: '/login' })
      //   }else{
      //     this.$router.push('/doubleElevenH5');
      //   }
      // }else if(this.showType == 'pc'){
      if(!getToken() && !this.userInfo){
        this.$store.dispatch('user/openLogin');
      }else{
        this.$router.push('/activityFor618');
      }
      // }
    },
    // 倒计时
    countdown(){
      let msTime = {
        day: "00",
        hour: "00",
        minutes: "00",
        seconds: "00",
      };
      if(!this.endTime){
        return msTime;
      }else{
        let end = this.endTime.toString().length == 10 ? this.endTime * 1000 : this.endTime;  // 判断是秒还是毫秒
        let timeDistance = end - new Date().getTime();
        if(timeDistance >= 0){
          msTime.day = Math.floor(timeDistance / 1000 / 60 / 60 / 24);
          msTime.hour = Math.floor(timeDistance / 1000 / 60 / 60 % 24);
          msTime.minutes = Math.floor(timeDistance / 1000 / 60 % 60);
          msTime.seconds = Math.floor(timeDistance / 1000 % 60).toFixed(0);
          if(msTime.day < 10){
            msTime.day = "0" + msTime.day;
          }
          if (msTime.hour < 10) {
            msTime.hour = "0" + msTime.hour;
          }
          if (msTime.minutes < 10) {
            msTime.minutes = "0" + msTime.minutes;
          }
          if (msTime.seconds < 10) {
            msTime.seconds = "0" + msTime.seconds;
          }
          this.timer = setTimeout(()=>{
            this.countdown();
          },1000);
        }
      }
      this.countDownObj = msTime;
    }
  }
}
</script>
<style scoped lang="scss">
*{
  padding: 0;
  margin: 0;
  font-style: normal;
}
.markbox{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: rgba(0,0,0,0.5);
  z-index: 2002;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dobuleEleven{
  width: 400px;
  height: 360px;
  background: url("../image/dialog/bg.png") no-repeat;
  background-size: cover;
  position: relative;
  user-select: none;
  padding: 0 19px;
  .title{
    width: 100%;
    text-align: center;
    margin-top: 32px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 24px;
  }
  .subtitle{
    display: inline-block;
    margin-top: 15px;
    width: 100%;
    height: 56px;
  }
  .countdown{
    width: 100%;
    height: 32px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    li{
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255,146,110,0.5);
      border-radius: 4px;
      border: 1px solid #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      position: relative;
      margin: 0 8px;
      &::after{
        content: ":";
        height: 100%;
        width: auto;
        position: absolute;
        right: -11px;
        top: 5px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
      &:first-child{
        margin-right: 16px;
        margin-left: 0;
      }
      &:first-child::after{
        content: "天";
        right: -20px;
      }
      &:last-child{
        margin-right: 0;
      }
      &:last-child::after{
        content: "";
      }
    }
  }
  .surplus{
    width: 88%;
    height: 135px;
    background: url("../image/dialog/subg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 50px;
    margin: 0 auto;
    .surplustitle{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      //background: linear-gradient(180deg, #FFE0E1 0%, #FFFFFF 100%);
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
      .today{
        display: inline-block;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 4px;
      }
      .me{
        display: inline-block;
        margin-top: 4px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .num{
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 40px;
    }
  }
  .snapup{
    width: 180px;
    height: 48px;
    background: linear-gradient(180deg, #FFFBD8 0%, #FFE2A5 47%, #FFC56A 100%);
    border-radius: 24px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #C52203;
    line-height: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(17px);
    cursor: pointer;
  }
  .gift{
    width: 180px;
    height: 180px;
    position: absolute;
    bottom: -14px;
    right: -120px;
  }
}
.dobuleElevenH5{
  width: 280px;
  height: 254px;
  padding: 0 16px;
  .title{
    font-size: 14px;
    margin-top: 14px;
  }
  .subtitle{
    height: 40px;
    margin-top: 10px;
  }
  .countdown{
    margin: 5px auto;
    li{
      width: 24px;
      height: 24px;
      font-size: 12px;
      &::after{
        font-size: 12px;
        top: 3px;
      }
    }
  }
  .surplus{
    height: 95px;
    .surplustitle{
      .today{
        font-size: 24px;
        line-height: 20px;
      }
      .me{
        font-size: 12px;
        line-height: 12px;
      }
    }
    .num{
      font-size: 24px;
      line-height: 24px;
    }
  }
  .gift{
    width: 98px;
    height: 98px;
    right: -46px;
  }
  .snapup{
    width: 120px;
    height: 36px;
    font-size: 14px;
    line-height: 14px;
    transform: translateY(6px);
  }
}
</style>
